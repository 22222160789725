<div class=top-button-wrapper>
	<div class="button-wrapper">
		<select id="dropdown" class="custom-select" (change)="onLoadSamples()">
				<option value="choose" selected="selected">Choose</option>
				<option value="upload">Custom files</option>
		    <option value="jl.txt" >jl</option>
		    <option value="loaduse1.txt" >loaduse</option>
		    <option value="addOne.txt" >addOne</option>
		</select>
		<div  class="upload-btn-wrapper" *ngIf="showSelectFile">
		<button class="upload-btn">
			{{uploadButtonText}}
		</button>
		<input  class="file-upload-input" type="file" id="file-input" (change)="onFileSelect()" />
	</div>
		<button class="control-buttons" (click)="onClickContinue()" [disabled]="!loadComponent">Continue</button>
		<button class="control-buttons" (click)="onClickStep()" [disabled]="!loadComponent">Step</button>
		<button class="control-buttons" (click)="onClickReset()" [disabled]="!loadComponent">Reset</button>
    <button [routerLink]="'/home'" class="regular-buttons" (click)="onClickReset()">Home</button>
	</div>
	<app-clock-cycle [cycle]="cycle"></app-clock-cycle>
</div>

<app-code [fileContent]="fileContent" [dstall]="dstall" [dbubble]="dbubble"
[reset]="reset"></app-code>

