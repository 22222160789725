<div class="pipeline-wrapper">
	<div [ngClass]="{
		'fetch-stage-stall': f_state === 'STALL',
		'fetch-stage-normal': f_state === 'NORMAL',
		'fetch-stage': f_state === 'NA'
	}">
		<p class="register-title">F ({{f_state}})</p>
		<div class="values-wrapper">
			<p class="register-value">predPC: {{f_predPC}} </p>
		</div>
	</div>
	<div [ngClass]="{
		'decode-stage-bubble': d_state === 'BUBBLE',
		'decode-stage-stall': d_state === 'STALL',
		'decode-stage-normal': d_state === 'NORMAL',
		'decode-stage': d_state === 'NA'
	}">
		<p class="register-title">D ({{d_state}})</p>
		<div class="values-wrapper">
			<p class="register-value-addr">addr: {{d_addr}}</p>
			<p class="register-value">stat: {{d_stat}} </p>
			<p class="register-value">icode: {{d_icode}} </p>
			<p class="register-value">ifun: {{d_ifun}} </p>
			<p class="register-value">rA: {{d_rA}} </p>
			<p class="register-value">rB: {{d_rB}} </p>
			<p class="register-value">valC: {{d_valC}} </p>
			<p class="register-value">valP: {{d_valP}} </p>
		</div>
	</div>
	<div [ngClass]="{
		'execute-stage-bubble': e_state === 'BUBBLE',
		'execute-stage-stall': e_state === 'STALL',
		'execute-stage-normal': e_state === 'NORMAL',
		'execute-stage': e_state === 'NA'
	}">
		<p class="register-title">E ({{e_state}})</p>
		<div class="execute-stage-value-wrapper">
			<div class="execute-values-wrapper">
				<p class="register-value-addr">addr: {{e_addr}}</p>
				<p class="register-value">stat: {{e_stat}} </p>
				<p class="register-value">icode: {{e_icode}} </p>
				<p class="register-value">ifun: {{e_ifun}} </p>
				<p class="register-value">valC: {{e_valC}} </p>
				<p class="register-value">valA: {{e_valA}} </p>
				<p class="register-value">valB: {{e_valB}} </p>
			</div>
			<div class="execute-values-wrapper">
				<p class="register-value">dstE: {{e_dstE}} </p>
				<p class="register-value">dstM: {{e_dstM}} </p>
				<p class="register-value">srcA: {{e_srcA}} </p>
				<p class="register-value">srcB: {{e_srcB}} </p>
			</div>
		</div>
	</div>
	<div [ngClass]="{
		'memory-stage-bubble': m_state === 'BUBBLE',
		'memory-stage-stall': m_state === 'STALL',
		'memory-stage-normal': m_state === 'NORMAL',
		'memory-stage': m_state === 'NA'
	}">
		<p class="register-title">M ({{m_state}})</p>
		<div class="values-wrapper">
			<p class="register-value-addr">addr: {{m_addr}}</p>
			<p class="register-value">stat: {{m_stat}} </p>
			<p class="register-value">icode: {{m_icode}} </p>
			<p class="register-value">Cnd: {{m_cnd}} </p>
			<p class="register-value">valE: {{m_valE}} </p>
			<p class="register-value">valA: {{m_valA}} </p>
			<p class="register-value">dstE: {{m_dstE}} </p>
			<p class="register-value">dstM: {{m_dstM}} </p>
		</div>
	</div>
	<div [ngClass]="{
		'writeback-stage-bubble': w_state === 'BUBBLE',
		'writeback-stage-stall': w_state === 'STALL',
		'writeback-stage-normal': w_state === 'NORMAL',
		'writeback-stage': w_state === 'NA'
	}">
		<p class="register-title">W ({{w_state}})</p>
		<div class="values-wrapper">
			<p class="register-value-addr">addr: {{w_addr}}</p>
			<p class="register-value">stat: {{w_stat}} </p>
			<p class="register-value">icode: {{w_icode}} </p>
			<p class="register-value">valE: {{w_valE}} </p>
			<p class="register-value">valM: {{w_valM}} </p>
			<p class="register-value">dstE: {{w_dstE}} </p>
			<p class="register-value">dstM: {{w_dstM}} </p>
		</div>
	</div>
</div>