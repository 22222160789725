<div class="condition-flags-wrapper">
	<table class="condition-flags">
		<tr>
	    <th class="title">OF</th>
	    <th class="title">SF</th> 
	    <th class="title">ZF</th>
	  </tr>
	  <tr>
	    <td class="value">{{OF}}</td>
	    <td class="value">{{SF}}</td>
	    <td class="value">{{ZF}}</td>
	  </tr>
	</table>
</div>