<div class="container">
  <div class="left">
    <app-control class="control"></app-control>
  </div>
  <div class="right">
    <app-registers class="registers"></app-registers>
    <app-condition-flags></app-condition-flags>
    <app-control-logic class="control-logic"></app-control-logic>
  </div>
  <div class="bottom">
    <app-pipeline-reg class="pipeline-div"></app-pipeline-reg>  
  </div>
</div>